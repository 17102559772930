import "./Mascotes.scss"
import Carousel from "react-bootstrap/Carousel";
import image1 from '../../images/mascotes/compact/mascote-3d-compatinho-compact-store-pose1.jpg';
import image2 from '../../images/mascotes/compact/mascote-3d-compatinho-compact-store-pose2.jpg';
import image3 from '../../images/mascotes/compact/mascote-3d-compatinho-compact-store-pose3.jpg';

function Compact() {
    return (
        <div id="mascotes">
            <div className="indent">
                <div className="mascote_images">
                    <Carousel>
                        <Carousel.Item>
                            <picture>
                                <img width="759" className="d-block w-100" src={image1} alt="Mascote 3D Compatinho, Criado para Compact Store - Pose 1." />
                            </picture>
                        </Carousel.Item>
                        <Carousel.Item>
                            <picture>
                                <img width="759" className="d-block w-100" src={image2} alt="Mascote 3D Compatinho, Criado para Compact Store - Pose 2." />
                            </picture>
                        </Carousel.Item>
                        <Carousel.Item>
                            <picture>
                                <img width="759" className="d-block w-100" src={image3} alt="Mascote 3D Compatinho, Criado para Compact Store - Pose 3." />
                            </picture>
                        </Carousel.Item>
                    </Carousel>
                </div>
                <div className="mascote_description">
                    <h2>Personagem: Compatinho</h2>
                    <h3>Cliente: Compact Store</h3>
                    <h1>Categorias: Mascote, Personagem, Mascote 3D, Personagem 3D, Mascote Cartoon, Mascote para empresa, Mascote para comércio, Criação de Mascote.</h1>
                    <h4>Técnica: Modelagem 3D</h4>
                    <p>Criado em: Outubro de 2024</p>
                </div>
            </div>

        </div>
    )
}


export default Compact;