import React from "react";
import Carousel from "react-bootstrap/Carousel";
import ImagePort from "./ImagePort";
import searchIcon from "../images/pesquisa.png";
import searchIcon2 from "../images/botao-saiba-mais-seta.png";

import cliente101 from "../images/portifolio/sucesso/Mascote-3D-1.webp";
import cliente102 from "../images/portifolio/sucesso/Mascote-3D-para-empresa-2.webp";
import cliente103 from "../images/portifolio/sucesso/Mascote-3D-3.webp";
import cliente104 from "../images/portifolio/sucesso/Mascote-3D-4.webp";
import cliente105 from "../images/portifolio/sucesso/Mascote3D-5.webp";
import cliente106 from "../images/portifolio/sucesso/Mascote3D-6.webp";
import cliente107 from "../images/portifolio/sucesso/Mascote3D-7.webp";
import cliente108 from "../images/portifolio/sucesso/Mascote3D-8.webp";
import cliente109 from "../images/portifolio/sucesso/Mascote3D-Personagem3D-9.webp";
import cliente110 from "../images/portifolio/sucesso/Mascote3D-Personagem3D-10.webp";
import cliente111 from "../images/portifolio/sucesso/Mascote3D-Personagem3D-11.webp";
import cliente112 from "../images/portifolio/sucesso/mascote-3d-compatinho-compact-store-pose1.jpg";
import cliente113 from "../images/portifolio/sucesso/Avatar-3D-13.webp";
import cliente114 from "../images/portifolio/sucesso/maromba-mania-3d-mascote3D-personagem-pose1.jpg";
import cliente115 from "../images/portifolio/sucesso/Avatar3D-15.webp";
import cliente116 from "../images/portifolio/sucesso/mascote-3D-16.webp";
import cliente117 from "../images/portifolio/sucesso/mascote-3D-17.webp";
import cliente118 from "../images/portifolio/sucesso/mascote-3D-18.webp";
import cliente119 from "../images/portifolio/sucesso/mascote3d-19.webp";
import cliente120 from "../images/portifolio/sucesso/mascote3d-20.webp";
import cliente121 from "../images/portifolio/sucesso/mascote3d-21.webp";
import cliente122 from "../images/portifolio/sucesso/Avatar3D-22.webp";
import cliente123 from "../images/portifolio/sucesso/mascote3d-23.webp";
import cliente124 from "../images/portifolio/sucesso/mascote3d-logo3d-24.webp";
import cliente125 from "../images/portifolio/sucesso/mascote3d-logo3d-25.webp";
import cliente126 from "../images/portifolio/sucesso/Avatar-3D-26.webp";
import cliente127 from "../images/portifolio/sucesso/mascote3D-27.webp";
import cliente128 from "../images/portifolio/sucesso/avatar-3D-mascote-persona-28.jpg";
import cliente129 from "../images/portifolio/sucesso/mascote-logo-3d-staffinho-29.jpg";
import cliente130 from "../images/portifolio/sucesso/mascote-3d-robo-sam-30.jpg";
import cliente131 from "../images/portifolio/sucesso/personagem3d-mascote3d-rr-31.jpg";
import cliente132 from "../images/portifolio/sucesso/personagem3d-mascote3d-ddvr-32.jpg";
import cliente133 from "../images/portifolio/sucesso/criacao-mascote-3d-castor-33.jpg";
import cliente134 from "../images/portifolio/sucesso/criacao-mascote-ativa-telecom-34.jpg";
import cliente135 from "../images/portifolio/sucesso/criacao-mascote-jvl-35.jpg";
import cliente136 from "../images/portifolio/sucesso/criacao-mascote-3d-36.webp";
import "./Clientes.scss"

import { NavLink } from 'react-router-dom'

function Clientes() {
    function scrollToTop() {
        window.scrollTo(0, 130)
    }

    return (
        <div id="clientes">
            <h1>Mascotes 3D</h1>
            <Carousel controls={false} indicators={false} interval={null}>
                <Carousel.Item>
                    <div className="container">
                        <div className="top">
                            <div className="mascote3d">
                                <NavLink to='/mascote-3d-joca' onClick={scrollToTop}>
                                    <img width="281" src={cliente101} alt="Mascote 3D - GSA Hospital Albert Einstein" />
                                    <span className="searchIcon">
                                        <picture>
                                            <source media="(max-width: 800px)" srcSet={searchIcon} alt="Ícone de pesquisa" />
                                            <img className="d-block w-100" src={searchIcon2} alt="Botão saiba mais" />
                                        </picture>
                                    </span>
                                </NavLink>
                            </div>
                            <div className="mascote3d">
                                <NavLink to='/mascote-3d-sam' onClick={scrollToTop}>
                                    <img width="281" src={cliente130} alt="Mascote Robô 3D - SAM" />
                                    <span className="searchIcon">
                                        <picture>
                                            <source media="(max-width: 800px)" srcSet={searchIcon} alt="Ícone de pesquisa" />
                                            <img className="d-block w-100" src={searchIcon2} alt="Botão saiba mais" />
                                        </picture>
                                    </span>
                                </NavLink>
                            </div>
                            <div className="mascote3d">
                                <NavLink to='/criacao-de-mascote-3d-castor-materiais' onClick={scrollToTop}>
                                    <img width="281" src={cliente133} alt="Mascote 3D Cartoon - Castor Center" />
                                    <span className="searchIcon">
                                        <picture>
                                            <source media="(max-width: 800px)" srcSet={searchIcon} alt="Ícone de pesquisa" />
                                            <img className="d-block w-100" src={searchIcon2} alt="Botão saiba mais" />
                                        </picture>
                                    </span>
                                </NavLink>
                            </div>
                            <div className="mascote3d">
                                <NavLink to='/rai-mascote-3d' onClick={scrollToTop}>
                                    <img width="281" src={cliente102} alt="Personagem 3D - Rema Tip Top" />
                                    <span className="searchIcon">
                                        <picture>
                                            <source media="(max-width: 800px)" srcSet={searchIcon} alt="Ícone de pesquisa" />
                                            <img className="d-block w-100" src={searchIcon2} alt="Botão saiba mais" />
                                        </picture>
                                    </span>
                                </NavLink>
                            </div>

                            <div className="mascote3d">
                                <NavLink to='/compatinho-mascote-3d-compact-store' onClick={scrollToTop}>
                                    <img width="281" src={cliente112} alt="Mascote 3D Compatinho da Compact Store" />
                                    <span className="searchIcon">
                                        <picture>
                                            <source media="(max-width: 800px)" srcSet={searchIcon} alt="Ícone de pesquisa" />
                                            <img className="d-block w-100" src={searchIcon2} alt="Botão saiba mais" />
                                        </picture>
                                    </span>
                                </NavLink>
                            </div>
                            <div className="mascote3d">
                                <NavLink to='/maromba-mania-personagem-3d' onClick={scrollToTop}>
                                    <img width="281" src={cliente114} alt="Mascote 3D criado para Maromba Mania" />
                                    <span className="searchIcon">
                                        <picture>
                                            <source media="(max-width: 800px)" srcSet={searchIcon} alt="Ícone de pesquisa" />
                                            <img className="d-block w-100" src={searchIcon2} alt="Botão saiba mais" />
                                        </picture>
                                    </span>
                                </NavLink>
                            </div>


                            <div className="mascote3d">
                                <NavLink to='/mascote-3d-rafaelito' onClick={scrollToTop}>
                                    <img width="281" src={cliente131} alt="Personagem 3D - Super Rafaelito" />
                                    <span className="searchIcon">
                                        <picture>
                                            <source media="(max-width: 800px)" srcSet={searchIcon} alt="Ícone de pesquisa" />
                                            <img className="d-block w-100" src={searchIcon2} alt="Botão saiba mais" />
                                        </picture>
                                    </span>
                                </NavLink>
                            </div>

                            <div className="mascote3d">
                                <NavLink to='/mascote-3d-staffinho' onClick={scrollToTop}>
                                    <img width="281" src={cliente129} alt="Mascote logo 3D - Staffinho" />
                                    <span className="searchIcon">
                                        <picture>
                                            <source media="(max-width: 800px)" srcSet={searchIcon} alt="Ícone de pesquisa" />
                                            <img className="d-block w-100" src={searchIcon2} alt="Botão saiba mais" />
                                        </picture>
                                    </span>
                                </NavLink>
                            </div>
                            <div className="mascote3d">
                                <NavLink to='/criacao-de-mascote-3d-ativa-telecom' onClick={scrollToTop}>
                                    <img width="281" src={cliente134} alt="Mascote 3D - Ativa Telecom" />
                                    <span className="searchIcon">
                                        <picture>
                                            <source media="(max-width: 800px)" srcSet={searchIcon} alt="Ícone de pesquisa" />
                                            <img className="d-block w-100" src={searchIcon2} alt="Botão saiba mais" />
                                        </picture>
                                    </span>
                                </NavLink>
                            </div>

                            <div className="mascote3d">
                                <NavLink to='/mascote-3d-ddvr' onClick={scrollToTop}>
                                    <img width="281" src={cliente132} alt="Personagem 3D - Ddvr Elevadores" />
                                    <span className="searchIcon">
                                        <picture>
                                            <source media="(max-width: 800px)" srcSet={searchIcon} alt="Ícone de pesquisa" />
                                            <img className="d-block w-100" src={searchIcon2} alt="Botão saiba mais" />
                                        </picture>
                                    </span>
                                </NavLink>
                            </div>

                            <div className="mascote3d">
                                <NavLink to='/mabu-mascote-3d-persona' onClick={scrollToTop}>
                                    <img width="281" src={cliente128} alt="Personagem 3D - Mabu Aviamentos" />
                                    <span className="searchIcon">
                                        <picture>
                                            <source media="(max-width: 800px)" srcSet={searchIcon} alt="Ícone de pesquisa" />
                                            <img className="d-block w-100" src={searchIcon2} alt="Botão saiba mais" />
                                        </picture>
                                    </span>
                                </NavLink>
                            </div>


                            <ImagePort image={cliente106} number={106} info={'Mascote 3D - Distribuidora Centauro'}></ImagePort>
                            <ImagePort image={cliente103} number={103} info={'Mascote 3D - Casa Giacomo'}></ImagePort>
                            <ImagePort image={cliente104} number={104} info={'Mascote 3D - Nesa Cursos'}></ImagePort>
                            <ImagePort image={cliente105} number={105} info={'Mascote 3D - Comercial Wady'}></ImagePort>
                            <ImagePort image={cliente107} number={107} info={'Mascote 3D - Estrela do Céu'}></ImagePort>
                            <ImagePort image={cliente108} number={108} info={'Mascote 3D - BL Fibra'}></ImagePort>
                            <ImagePort image={cliente111} number={111} info={'Mascote 3D - Bônus Solar'}></ImagePort>
                            <ImagePort image={cliente115} number={115} info={'Criação de Mascote 3D'}></ImagePort>
                            <ImagePort image={cliente113} number={113} info={'Avatar 3D'}></ImagePort>
                            <ImagePort image={cliente116} number={116} info={'Criação de Mascote 3D'}></ImagePort>
                            <ImagePort image={cliente117} number={117} info={'Mascote 3D'}></ImagePort>
                            <ImagePort image={cliente118} number={118} info={'Criação de Mascote 3D'}></ImagePort>
                            <ImagePort image={cliente109} number={109} info={'Mascote 3D - LRM Internet'}></ImagePort>
                            <ImagePort image={cliente119} number={119} info={'Criação de Mascote 3D'}></ImagePort>
                            <ImagePort image={cliente120} number={120} info={'Criação de Mascote 3D'}></ImagePort>
                            <ImagePort image={cliente135} number={135} info={'Criação de Mascote - JVL'}></ImagePort>
                            <ImagePort image={cliente121} number={121} info={'Criação de Mascote'}></ImagePort>
                            <ImagePort image={cliente122} number={122} info={'Avatar 3D Jipe Canastra'}></ImagePort>
                            <ImagePort image={cliente123} number={123} info={'Criação de Mascote'}></ImagePort>
                            <ImagePort image={cliente110} number={110} info={'Mascote 3D - Mercado Ageu'}></ImagePort>
                            <ImagePort image={cliente124} number={124} info={'Criação de Mascote'}></ImagePort>
                            <ImagePort image={cliente127} number={127} info={'Mascote 3D'}></ImagePort>
                            <ImagePort image={cliente136} number={136} info={'Criação de Mascote 3D - Start Life'}></ImagePort>
                            <ImagePort image={cliente125} number={125} info={'Criação de Mascote'}></ImagePort>
                            <ImagePort image={cliente126} number={126} info={'Avatar 3D Baratão Distribuidora de Moto Peças'}></ImagePort>
                        </div>
                    </div>
                </Carousel.Item>
            </Carousel>
        </div>
    )
}


export default Clientes;