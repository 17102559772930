import "./Mascotes.scss"
import Carousel from "react-bootstrap/Carousel";
import image1 from '../../images/mascotes/mabu/mascote3D-Personagem3D-mascote-persona-pose-1.jpg'
import image2 from '../../images/mascotes/mabu/mascote3D-Personagem3D-mascote-persona-pose-2.jpg'
import image3 from '../../images/mascotes/mabu/mascote3D-Personagem3D-mascote-persona-pose-3.jpg'
import image4 from '../../images/mascotes/mabu/mascote3D-Personagem3D-mascote-persona-pose-4.jpg'
import image5 from '../../images/mascotes/mabu/mascote3D-Personagem3D-mascote-persona-pose-5.jpg'

function Mabu() {
    return (
        <div id="mascotes">
            <div className="indent">
                <div className="mascote_images">
                    <Carousel>
                        <Carousel.Item>
                            <picture>
                                <img width="759" className="d-block w-100" src={image1} alt="Mascote 3D Persona Mabu, Criado para Mabu Aviamentos." />
                            </picture>
                        </Carousel.Item>
                        <Carousel.Item>
                            <picture>
                                <img width="759" className="d-block w-100" src={image2} alt="Mascote 3D Persona Mabu, Criado para Mabu Aviamentos." />
                            </picture>
                        </Carousel.Item>
                        <Carousel.Item>
                            <picture>
                                <img width="759" className="d-block w-100" src={image3} alt="Mascote 3D Persona Mabu, Criado para Mabu Aviamentos." />
                            </picture>
                        </Carousel.Item>
                        <Carousel.Item>
                            <picture>
                                <img width="759" className="d-block w-100" src={image4} alt="Mascote 3D Persona Mabu, Criado para Mabu Aviamentos." />
                            </picture>
                        </Carousel.Item>
                        <Carousel.Item>
                            <picture>
                                <img width="759" className="d-block w-100" src={image5} alt="Mascote 3D Persona Mabu, Criado para Mabu Aviamentos." />
                            </picture>
                        </Carousel.Item>
            
                    </Carousel>
                </div>
                <div className="mascote_description">
                    <h2>Personagem: Mabu</h2>
                    <h3>Cliente:  Mabu Aviamentos</h3>
                    <h1>Categorias: Mascote, Personagem, Mascote 3D, Personagem 3D, Mascote Persona, Mascote para empresa, Criação de Mascote, Mascote Cartoon.</h1>
                    <h4>Técnica: Ilustração</h4>
                    <p>Criado em: Junho de 2024</p>
                </div>
            </div>

        </div>
    )
}


export default Mabu;