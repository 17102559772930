import Ativa from "../../components/mascotes/Ativa";

function AtivaPage() {
    return (
        <Ativa></Ativa>
    )
}

export default AtivaPage;

