import Castor from "../../components/mascotes/Castor";

function CastorPage() {
    return (
        <Castor></Castor>
    )
}

export default CastorPage;

