import "./Mascotes.scss"
import Carousel from "react-bootstrap/Carousel";
import image1 from '../../images/mascotes/castor/criacao-de-mascote-3d-castor-center-pose-1.jpg'
import image2 from '../../images/mascotes/castor/criacao-de-mascote-3d-castor-center-pose-2.jpg'
import image3 from '../../images/mascotes/castor/criacao-de-mascote-3d-castor-center-pose-3.jpg'

function Castor() {
    return (
        <div id="mascotes">
            <div className="indent">
                <div className="mascote_images">
                    <Carousel>
                        <Carousel.Item>
                            <picture>
                                <img width="759" className="d-block w-100" src={image1} alt="Criação de Mascote, Mascote 3D Castor Center." />
                            </picture>
                        </Carousel.Item>
                        <Carousel.Item>
                            <picture>
                                <img width="759" className="d-block w-100" src={image2} alt="Criação de Mascote, Mascote 3D Castor Center" />
                            </picture>
                        </Carousel.Item>
                        <Carousel.Item>
                            <picture>
                                <img width="759" className="d-block w-100" src={image3} alt="Criação de Mascote, Mascote 3D Castor Center" />
                            </picture>
                        </Carousel.Item>
                    </Carousel>
                </div>
                <div className="mascote_description">
                    <h2>Personagem: Castor</h2>
                    <h3>Cliente: Castor Center</h3>
                    <h1>Categorias: Mascote, Personagem, Mascote 3D, Personagem 3D, Mascote para empresa, Mascote cartoon, Criação de Mascote.</h1>
                    <h4>Técnica: Modelagem 3D</h4>
                    <p>Criado em: Janeiro de 2025</p>
                </div>
            </div>

        </div>
    )
}


export default Castor;