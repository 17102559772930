import './App.scss';
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/js/src/carousel.js"
import "bootstrap/js/src/util/index.js"
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import HomePage from './pages/Home';
import Portifolio from './pages/Portfolio';
import RootLayout from './pages/Root';
import ErrorPage from './pages/Error';
import Fantasias from './pages/Fantasias';
import Page1 from './pages/blog/Page1';
import Page2 from './pages/blog/Page2';
import Page3 from './pages/blog/Page3';
import Page4 from './pages/blog/Page4';
import Page5 from './pages/blog/Page5';
import JocaPage from './pages/mascotes/JocaPage';
import StaffinhoPage from './pages/mascotes/StaffinhoPage';
import SamPage from './pages/mascotes/SamPage';
import RafaelitoPage from './pages/mascotes/RafaelitoPage';
import DdvrPage from './pages/mascotes/DdvrPage';
import RaiPage from './pages/mascotes/RaiPage';
import MabuPage from './pages/mascotes/MabuPage';
import Page6 from './pages/blog/Page6';
import Page7 from './pages/blog/Page7';
import Page8 from './pages/blog/Page8';
import MarombaPage from './pages/mascotes/MarombaPage';
import CompactPage from './pages/mascotes/CompactPage';
import CastorPage from './pages/mascotes/CastorPage';
import AtivaPage from './pages/mascotes/AtivaPage';


const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      { path: '/', element: <HomePage /> },
      { path: '/portfolio', element: <Portifolio /> },
      { path: '/fantasias', element: <Fantasias /> },
      { path: '/blog', element: <Page1 /> },
      { path: '/blog-mascote-top-mascote-3d', element: <Page1 /> },
      { path: '/blog-mascote-top-mascote-para-empresa', element: <Page2 /> },
      { path: '/blog-mascote-top-criacao-de-mascote', element: <Page3 /> },
      { path: '/blog-mascote-top-personagens-exclusivos', element: <Page4 /> },
      { path: '/blog-mascote-top-interacao-mascotes', element: <Page5 /> },
      { path: '/blog-mascotes-habitaveis', element: <Page6 /> },
      { path: '/blog-mascote-3d-estatico-ou-modelagem-3d-360', element: <Page7 /> },
      { path: '/mascotes-de-vestir-fantasias-personagens', element: <Page8 /> },
      { path: '/mascote-3d-joca', element: <JocaPage/> },
      { path: '/mascote-3d-sam', element: <SamPage /> },
      { path: '/mascote-3d-staffinho', element: <StaffinhoPage /> },
      { path: '/mascote-3d-ddvr', element: <DdvrPage /> },
      { path: '/mascote-3d-rafaelito', element: <RafaelitoPage /> },
      { path: '/rai-mascote-3d', element: <RaiPage /> },
      { path: '/mabu-mascote-3d-persona', element: <MabuPage /> },
      { path: '/maromba-mania-personagem-3d', element: <MarombaPage /> },
      { path: '/compatinho-mascote-3d-compact-store', element: <CompactPage /> },
      { path: '/criacao-de-mascote-3d-castor-materiais', element: <CastorPage /> },
      { path: '/criacao-de-mascote-3d-ativa-telecom', element: <AtivaPage /> },
    ],
  },

]);




function App() {
  return (
    <div className="App">
      <RouterProvider router={router}></RouterProvider>
    </div>
  );
}

export default App;
